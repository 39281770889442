<template>
  <div class="wrapper mt-7">
    <Contact></Contact>
    <div class="main">
      <div class="container">
        <div class="row mb-3 mt-5 pt-4">
          <div>
            <p>
              <span style="color: #ff0000;">data</span
              ><span style="color: #0000cd;">fields</span> welcomes enquiries
              from anyone. if&nbsp;you want more information regarding our
              &nbsp;<strong>Portugal Data Check (PDC)</strong>&nbsp;portal or if
              you have any other query, comments or feedback please contact
              Portugal Data Check (PDC), via
              <strong
                >Skype, on-line Chat,&nbsp;customer support line or you
                can&nbsp;</strong
              >
              can email us on;
            </p>
          </div>
          <div>
            <ul>
              <li>
                Please direct your sales queries or PDC related queries to:
                <a
                  class="text-myprimary"
                  href="mailto:pdc_support@datafields.net"
                  target="_blank"
                  >pdc_support@datafields.net</a
                >
              </li>
              <li>
                Contact datafields management directly for business proposals,
                comments or suggestions:
                <a
                  class="text-myprimary"
                  href="mailto:management@datafields.net"
                  target="_blank"
                  >management@datafields.net</a
                >
              </li>
              <li>
                Contact webmaster:
                <a
                  class="text-myprimary"
                  href="mailto:webmaster@datafields.net"
                  target="_blank"
                  >webmaster@datafields.net</a
                >
              </li>
              <li>
                Any other issues:
                <a
                  class="text-myprimary"
                  href="mailto:info@datafields.net"
                  target="_blank"
                  >info@datafields.net</a
                >
                /
                <a
                  class="text-myprimary"
                  href="mailto:ops@datafields.net"
                  target="blank"
                  >ops@datafields.net</a
                >
              </li>
            </ul>
          </div>
          <div>
            <p>
              Telephone :
              <a href="tel:+351 22 961 77 00"><b>+351 22 961 77 00</b></a>
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="info">
              <div
                class="icon icon-lg icon-shape shadow rounded-circle bg-myprimary text-white"
              >
                <i class="fa fa-map-marker"></i>
              </div>
              <h6 class="info-title text-uppercase">
                <b>Datafields - Marketing and IT Services Ltd <span class="text-myprimary">(Portugal)</span></b>
              </h6>
              <p class="description">
                R. Agostinho da Silva Rocha 945
                <br />
                4475-451 Nogueira Maia Portugal
                <br />
                Phone :
                <a href="tel:+351 22 961 77 00" target="_blank"
                  ><b>+351 22 961 77 00</b></a
                >
                <br />
                <a href="tel:+351 22 493 33 24" target="_blank"
                  ><b>+351 22 493 33 24</b></a
                >
              </p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="info">
              <div
                class="icon icon-lg icon-shape shadow rounded-circle bg-myprimary text-white"
              >
                <i class="fa fa-map-marker"></i>
              </div>
              <h6 class="info-title text-uppercase ">
                <b>Datafields Ltd <span class="text-myprimary">(UK)</span></b>
              </h6>
              <p class="description">
                35 Shelley Road
                <br />
                High Wycombe HP11 2UW
                <br />
                Buckinghamshire UK
                <br />
                Phone :
                <a
                  class=""
                  href="tel:+44 208 1448486"
                  target="_blank"
                  ><b>+44 208 1448486</b></a
                >
              </p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="info">
              <div
                class="icon icon-lg icon-shape shadow rounded-circle bg-myprimary text-white"
              >
                <i class="fa fa-map-marker"></i>
              </div>
              <h6 class="info-title text-uppercase">
                <b>Datafields Ltd <span class="text-myprimary">(Sri Lanka)</span></b>
              </h6>
              <p class="description">
                111 1/2 Maya Avenue (High Level Raod) Kirulapona,
                <br />
                Colombo 06 Sri Lanka
                <br />
                Phone :
                <a
                  class=""
                  href="tel:+94 112 586418"
                  target="_blank"
                  ><b>+94 112 586418</b></a
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
const Contact = () =>
  import(/* webpackChunkName: "Contact"*/ "@/components/Contact.vue");
export default {
  bodyClass: "contact-page",
  components: {
    Contact
  },
  metaInfo: {
    title: "Contact Us"
  }
};
</script>
<style></style>
